<template>
    <div>
        <v-layout row wrap :align-center="!wideLayout" :justify-center="!wideLayout" :fill-height="!wideLayout">
            <v-flex xs12 sm12 :md12="wideLayout" :lg12="wideLayout" :md10="!wideLayout" :lg10="!wideLayout" :xl8="!wideLayout">
                

                <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
                :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageOptions: [5,10,15,25,50, 100]
                }"
                >
                <template v-slot:top>
                    <v-toolbar class="elevation-1" color="white">
                        <v-toolbar-title class="font-weight-regular body-1">{{ settings.title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-flex xs4 md4 lg4 v-show="breakPoint">
                            <!--<v-text-field class="pt-4"  label="Search..." @keyup.esc="search = ''" v-model="search" append-icon="mdi-magnify" single-line></v-text-field>-->
                            <v-text-field class="pt-4"  label="Search..." @keyup.esc="search = ''" @keyup="doSearch()" v-model="search" append-icon="mdi-magnify" single-line></v-text-field>
                        </v-flex>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-tooltip v-if="btnRefresh" top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="refreshDataTable" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                    <v-icon>mdi-sync</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh data</span>
                        </v-tooltip>
                        <v-tooltip v-if="btnToggleColumns" top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleColumns()" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <span>Show / hide columns</span>
                        </v-tooltip>
                    </v-toolbar>
                    <br>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_info"
                                color="success"
                                small
                                class="mr-2"
                                @click="viewItemInfoDialog(item)"
                                v-on="on"
                            >
                                mdi-information-outline
                            </v-icon>
                        </template>
                        <span>View details</span>
                    </v-tooltip>
                    <!--<v-tooltip top >
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_edit"
                                color="success"
                                small
                                class="mr-2"
                                @click="editItem(item)"
                                v-on="on"
                            >
                                mdi-account-edit
                            </v-icon>-->
                            <!--<a :href="editItemUrl(item)" style="text-decoration:none;">
                                <v-icon
                                id="dt_btn_edit"
                                color="success"
                                small
                                class="mr-2"
                                v-on="on"
                                >
                                
                                mdi-account-edit
                                </v-icon>
                            </a>-->

                        <!--</template>
                        <span>Uredi uporabnika</span>
                    </v-tooltip>-->
                    <!--<v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_delete"
                                color="error"
                                small
                                @click="deleteItem(item)"
                                v-on="on"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Izbriši</span>
                    </v-tooltip>-->
                </template>

                <template v-slot:item.invoice_nr="{ item }">
                    <v-chip label class="" v-if="item.invoice_nr !== null && item.invoice_nr !=='null' && item.invoice_nr !== ''" :color="getInvoiceNrColor(item)" dark small>{{ item.invoice_nr }}</v-chip>
                    <span v-else>{{ item.invoice_nr }}</span>
                </template>

                <template v-slot:item.payment_method_name="{ item }">
                    <v-chip label class="" v-if="item.payment_method_name !== null && item.payment_method_name !=='null' && item.payment_method_name !== ''" :color="paymentMethodColor(item)" dark small>{{ item.payment_method_name }}</v-chip>
                    <span v-else>{{ item.payment_method_name }}</span>
                </template>

                <template v-slot:item.transaction_id="{ item }">
                    <v-chip label class="" v-if="item.transaction_id !== null && item.transaction_id !=='null' && item.transaction_id !== ''" :color="transactionIdColor(item)" dark small>{{ item.transaction_id }}</v-chip>
                    <span v-else>{{ item.transaction_id }}</span>
                </template>
                
                <template v-slot:progress>
                    <v-progress-linear absolute  indeterminate :height="progressHeight()"></v-progress-linear>
                </template>
                <template slot="no-data">
                    <div class="no_data" v-show="!searchQuery">{{ (this.loading) ? "Data processing in progress..." : "No data to display." }}</div>
                    <div class="no_data" v-show="searchQuery">{{ (this.loading) ? 'Data processing for keyword "'  + this.search + '" is in progress...' :  `No results for keyword »${this.search}«.`}}</div>
                </template>
                <template slot="loading">
                    <div class="no_data">Data processing in progress ...</div>
                </template>
                </v-data-table>
            </v-flex>

            <v-dialog v-model="dialog" persistent max-width="490">
                <v-card>
                    <v-card-title></v-card-title>
                    <v-card-text>
                        <v-icon text>mdi-delete</v-icon>
                        {{ dialog_text }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="error" @click.native="dialogCancel()">Prekliči</v-btn>
                        <v-btn text :loading="loading" color="success" @click.native="dialogConfirm()">Nadaljuj</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
        
        <invoice-info-dialog></invoice-info-dialog>
    </div>
</template>

<script>
import { post, get, put, destroy, API_ENDPOINT, DEMO_API_ENDPOINT } from '@/helpers/api'
import { EventBus } from '@/main.js'
import InvoiceInfoDialog from '@/components/InvoiceInfoDialog.vue'

export default {
    props: ['settings', 'queryParameters', 'boxedLayout'],
    name: 'invoices-data-table',

    components: {
        InvoiceInfoDialog
    },

    data: () => ({
        search: '',
        totalItems: 0,
        items: [],
        loading: true,
        options: {},
        rowsPerPageItems: [5,10,15,25,50,100, -1], 
        interval: null,
        dialog: false,
        dialog_text: '',
        itemToDelete: null,
        searchTimer: null
    }),

    watch: {
        options: {
            handler () {
            this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })

                //window.console.log(this.options)
                //window.console.log(this.params)
            },
            deep: true,
        },

        /*search(newValue) {
            this.$store.commit('SET_SEARCH', newValue)
            this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })
        },
        */

        searchFromStore(newValue) {
            this.search = newValue
            this.doSearch()
        }

        /*items(newValue) {
            window.console.log(this.items)
        }*/
    },

    computed: {
        params(nv) {
            return {
                ...this.options,
                query: this.search
            }
        },

        searchQuery() {
           return (this.search != null && this.search.trim() != '') ? true : false;
       },

       btnRefresh() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.refresh) {
               return true
           }

           return false
       },

       btnExport() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.export) {
               return true
           }

           return false
       },

       btnAddNew() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.addNew) {
               return true
           }

           return false
       },

       btnToggleColumns() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.toggleColumns && 
                this.settings.eventBus && this.settings.eventBus.toggleColumnsId != null && this.settings.eventBus.toggleColumnsId.trim() != ''
           ) {
               return true
           }

           return false
       },

       breakPoint() {
           if(this.$vuetify.breakpoint.mdAndUp) {
                return true;
            }

             return false;
       },

       apiParams() {
           let params = {
                ...this.options,
                query: this.search,
                columns: this.settings.headers.map(header => {
                    return header.value
                })
            }

            if(this.queryParameters && this.queryParameters != null) {
                let queryParams = this.queryParameters
                params = {
                    ...params,
                    ...queryParams
                }
            }

            //window.console.log("queryParams")
            //window.console.log(params)

           return params;
       },

       headers() {
           return this.settings.headers.filter(header => {
               if(header.visibility) {
                   return header
               }
           });
       },

       wideLayout() {
           if(this.boxedLayout !== undefined) {
               if(this.boxedLayout === true) {
                   return false
               }

               return true
           }

           return true
       },

       searchFromStore: {
           get() {
               return this.$store.getters.search
           },

           set(value) {
               this.$store.commit('SET_SEARCH', this.search)
           }
       }
    },

    methods: {
        getDataFromApi () {
            this.loading = true
            
            if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'totalItems')) {
                if(this.settings.eventBus.totalItems) {
                    EventBus.$emit(this.settings.eventBus.totalItems.event, {progress: true, value: 0})
                }
            }

            if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'sumItems')) {
                if(this.settings.eventBus.sumItems) {
                    EventBus.$emit(this.settings.eventBus.sumItems.event, {progress: true, queryParameters: this.apiParams})
                }
            }

            return new Promise((resolve, reject) => {
                //post(API_ENDPOINT + this.settings.endpoint, this.apiParams)
                post(API_ENDPOINT + this.settings.endpoint, this.apiParams)            
                .then(response => {
                    //window.console.log("API data")
                    //window.console.log(response)
                    const {
                       sortBy,
                       sortDesc, 
                       page,
                       itemsPerPage 
                    } = this.options;

                    //this.pagination.rowsPerPage = response.data.meta.per_page
                    //console.log(response)
                    const items = response.data.data
                    const totalItems = response.data.meta.total

                    if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'totalItems') ) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {value: totalItems})
                        }
                    }

                    resolve({items, totalItems})
                })
                .catch(error => {
                    if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {value: 0})
                        }
                    }
                    reject(error)
                })
                .finally(() => {
                    this.loading = false
                    if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') && Object.prototype.hasOwnProperty.call(this.settings.eventBus, 'totalItems')) {
                        if(this.settings.eventBus.totalItems) {
                            EventBus.$emit(this.settings.eventBus.totalItems.event, {progress: false})
                        }
                    }
                })
            })
      },

      addItem(item) {
          //window.console.log(item)
          
      },

      editItem(item) {
           //window.console.log(item)
           this.$router.push({
                name: 'edit_user',
                params: {
                    id: item.user_uuid
                }
            })

       },

       editItemUrl(item) {
           let itemLocation = ""
           let location = this.$router.resolve({name: 'edit_user', params: {id: item.user_uuid }})
           itemLocation = window.location.origin + location.href;

           return itemLocation

       },

       deleteItem(item) {
           this.itemToDelete = item
           //console.log(item)
            if(Object.prototype.hasOwnProperty.call(this.settings, 'deleteItem')  && this.settings.deleteItem != null) {
                this.dialog_text = this.settings.deleteItem.confirmationText.replace('{str_to_replace}', this.itemToDelete[this.settings.deleteItem.replaceStrWithColumnValue])
            }
            else {
                this.dialog_text = 'Ste prepričani da želite odstraniti zapis' + this.itemToDelete.id + '?'
            }
           this.dialog = true

           //console.log(this.itemToDelete)
       },

       viewItemInfoDialog(item) {
           EventBus.$emit('VIEW_INVOICE_ITEM_DIALOG', item);
       },

       dialogConfirm() {
           new Promise( (resolve, reject) => {
               this.loading = true
               if(this.settings.deleteItem.vuexAction != '' && this.settings.deleteItem.vuexAction != null) {
                   //this.$store.dispatch(this.settings.deleteItem.vuexAction, this.itemToDelete.id)
                   this.$store.dispatch(this.settings.deleteItem.vuexAction, this.itemToDelete[this.settings.editItem.routeParameterValue])
                    .then(response => {
                        this.dialog = false
                        this.loading = false    
                        this.$store.state.snackbar.mode = ''
                        this.$store.state.snackbar.display = true
                        this.$store.state.snackbar.text = this.settings.deleteItem.dialogConfirmSuccessText.replace('{str_to_replace}', this.itemToDelete[this.settings.deleteItem.replaceStrWithColumnValue])

                        this.itemToDelete = null
                        this.refreshDataTable()

                    })
                    .catch(error => {
                        //window.console.log(error)
                        this.dialog = false
                        this.loading = false    
                        this.$store.state.snackbar.mode = 'multi-line'
                        this.$store.state.snackbar.display = true
                        this.$store.state.snackbar.text = this.settings.deleteItem.dialogConfirmFailText.replace('{str_to_replace}', this.itemToDelete[this.settings.deleteItem.replaceStrWithColumnValue])
                        this.itemToDelete = null
                    })
               } else {
                    this.dialog = false
                    this.loading = false    
                    this.$store.state.snackbar.mode = 'multi-line'
                    this.$store.state.snackbar.display = true
                    this.$store.state.snackbar.text = this.settings.deleteItem.dialogConfirmFailText.replace('{str_to_replace}', this.itemToDelete[this.settings.deleteItem.replaceStrWithColumnValue])
                    this.itemToDelete = null
               }
           })
        },

       dialogCancel() {
            this.dialog_confirm = false
            this.dialog = false
            this.itemToDelete = null
       },

       addNewItem() {
           if(this.settings.toolbar.buttons.addNewRoute != '' && this.settings.toolbar.buttons.addNewRoute != null) {
               this.$router.push({name: this.settings.toolbar.buttons.addNewRoute})
           }
       },

       refreshDataTable() {
           this.getDataFromApi()
                .then(data => {
                   this.items = data.items
                   this.totalItems = data.totalItems
               })
               .catch(error => {
                   this.items = [];
                   this.totalItems = 0;
                   window.console.log(error)
               })
       },

       toggleColumns() {
           if(this.settings && this.settings.toolbar && this.settings.toolbar.buttons && this.settings.toolbar.buttons.toggleColumns && 
                this.settings.eventBus && this.settings.eventBus.toggleColumnsId != null && this.settings.eventBus.toggleColumnsId.trim() != '') {
                    EventBus.$emit(this.settings.eventBus.toggleColumnsId, {
                        toggleColumnsMenu: true
                    });
            }
       },

       toggleDataTableColumns(data) {
           if(Object.prototype.hasOwnProperty.call(data, 'dataTableHeaders') ) {
               //window.console.log(data)
                for(var i=0; i<this.settings.headers.length; i++) {
                    var headerFound = false;
                    for(var k=0; k<data.dataTableHeaders.length; k++) {
                        if(this.settings.headers[i].value == data.dataTableHeaders[k]) {
                            this.settings.headers[i].visibility = true
                            headerFound = true
                        }
                    }

                    if(headerFound == false) {
                        //console.log(this.dataTableSettings.headers[i])
                        this.settings.headers[i].visibility = false
                    }
                }

           }
       },

       progressHeight() {
           if(Object.prototype.hasOwnProperty.call(this.settings, 'progressHeight')  && this.settings.progressHeight != null && this.settings.progressHeight != '') {
               return this.settings.progressHeight
           }

           return 3
       },


       paymentMethodColor(item) {
           if(item.payment_method == 1) {
               return "error"
           }

           if(item.payment_method == 2) {
               return "#6D4C41"
           }

           return "success";
       },

       transactionIdColor(item) {
           if(item.payment_method == 3) { //cash
                return "#5E35B1"
           }

           return ""
       },

       getInvoiceNrColor(item) {
           if(item.sent_again_to_fiscalization == 1) {
               return "warning"
           }

           return "info"
       },

       getInvoiceNrTooltipLabel() {
           return "Fiscalization succeeded in the first attempt";
       },

       doSearch() {
           let vm = this
           if(this.searchTimer) {
               clearTimeout(this.searchTimer)
               this.searchTimer = null
           }

           this.searchTimer = setTimeout(() => {
               vm.getDataFromApi()
                .then(data => {
                   vm.items = data.items
                   vm.totalItems = data.totalItems
               })
               .catch(error => {
                   vm.items = [];
                   vm.totalItems = 0;
                   window.console.log(error)
               })
           }, 600)
       }
    },

    created() {
        /*window.console.log("DataTableSettings");
        window.console.log(this.settings)

        window.console.log("Query Parameters")
        window.console.log(this.queryParameters)*/
    },

    mounted() {
        if(this.settings && this.settings.sortColumn) {
            this.options.sortBy = this.settings.sortColumn
        }

        if(this.settings && this.settings.sortDirection && this.settings.sortDirection == 'desc') {
            this.options.sortDesc = this.settings.sortDirection
        }

        if(Object.prototype.hasOwnProperty.call(this.settings, 'eventBus') ) {
            if(this.settings.eventBus.refreshDataTable != null && this.settings.eventBus.refreshDataTable.trim() != '' ) {
                EventBus.$on(this.settings.eventBus.refreshDataTable, (data) => {
                    this.refreshDataTable()
                })
            }

            if(this.settings.eventBus.search != null && this.settings.eventBus.search.trim() != '') {
                EventBus.$on(this.settings.eventBus.search, (data) => {
                    this.search = data
                })
            }

            if(this.settings.id != null && this.settings.id !== undefined) {
                EventBus.$on(this.settings.id, (data) => {
                    this.toggleDataTableColumns(data)
                })
            }
        }

        if(this.settings && this.settings.updateInterval) {
            this.interval = setInterval(() => {
                if(this.loading != true) {
                    this.getDataFromApi()
                    .then(data => {
                        this.items = data.items
                        this.totalItems = data.totalItems
                    })
                    .catch(error => {
                        this.items = [];
                        this.totalItems = 0;
                        window.console.log(error)
                    })
                }
            }, process.env.VUE_APP_DATA_TABLES_REFRESH_INTERVAL || 180000)
       }

       if(this.settings && this.settings.rowsPerPage) {
           this.options.itemsPerPage = this.settings.rowsPerPage
       }

       //window.console.log("Boxed Layout: " + this.boxedLayout)
       //window.console.log(this.items)
    },

    beforeDestroy() {
        clearTimeout(this.searchTimer)
    },

    destroyed() {
        clearInterval(this.interval)
        if(this.settings && this.settings.eventBus  && this.settings.eventBus.refreshDataTable) {
            if(this.settings.eventBus.refreshDataTable != null && this.settings.eventBus.refreshDataTable.trim() != '' ) {
                EventBus.$off(this.settings.eventBus.refreshDataTable)
            }
        }
        
        EventBus.$off(this.settings.id)
    }
}

</script>

<style scoped>
    .no_data {
        text-align:center !important;
    }

    #style-11::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    
    #style-11::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
        border: 1px solid #ccc;
    }

    #style-11::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(left, #fff, #e4e4e4);
        border: 1px solid #aaa;
    }

    #style-11::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }

    #style-11::-webkit-scrollbar-thumb:active {
        background: linear-gradient(left, #22ADD4, #1E98BA);
    }

    .toolbar_btn_fab {
        margin-right:10px;
    }

    #dt_btn_edit {
        font-size:18px !important;
    }

    #dt_btn_delete {
        font-size:18px !important;
    }

    #dt_btn_info {
        font-size:18px !important;
    }

    .no_data {
        color: #000 !important;
    }

    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }


</style>